import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import Seo from 'src/lib/components/Seo';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

interface Props {
  data: GatsbyTypes.BasicPageQuery,
  // pageContext: { caseStudyId: string },
}

export const query = graphql`
  query BasicPage($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      ...BasicPageWithHero
    }
  }
`;


const CareerPage: React.FC<Props> = ({ data }) => {
  const pageData = BasicQuery_SitePage(data.wpPage || {});

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: pageData.navTitle },
  ];

  return (
    <>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
      />
      <PageHeader
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        {pageData.contentHtml && (
          <Block>
            <ContentParser>{pageData.contentHtml}</ContentParser>
          </Block>
        )}
      </Container>
    </>
  );
};

export default CareerPage;
